import React from 'react'
import { keyframes } from '@emotion/core'
import { Send } from 'icons'
import { Flex, Box, Text, Icon } from 'components'

const animation = keyframes`
  0% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(0);
  }
`

const Toggle = ({ onClick, attention }) => {
  return (
    <Flex
      sx={{
        pl: 1,
        pr: 2,
        py: 2,
        width: ['unset', 140],
        alignItems: 'center',
        bg: 'blazingOrange',
        boxShadow: 'md',
        borderRadius: 'default',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        cursor: 'pointer',
        transform: 'translateX(4px)',
        transition: 'transform 100ms ease-out',

        '&:hover, &:active, &:focus': {
          transform: 'translateX(0)',
        },

        ...(attention && {
          animation: `${animation} 250ms ease-out 1250ms`,
          animationIterationCount: '3',
        }),
      }}
      onClick={onClick}
    >
      <Box px={2}>
        <Text
          sx={{
            color: 'white',
            fontWeight: 500,
            textAlign: 'center',
            display: ['none', 'block'],
          }}
        >
          Jetzt Kontakt aufnehmen
        </Text>
        <Icon
          sx={{
            display: ['block', 'none'],
          }}
          source={Send}
          color="white"
          size={18}
        />
      </Box>
    </Flex>
  )
}

export default Toggle
