import { padding } from 'styled-system'

const breakpoints = ['40em', '52em', '64em', '80em']

// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const theme = {
  breakpoints,
  colors: {
    text: '#182D58',
    selection: '#1f487e47',

    darkNavy100: '#182D58',
    darkNavy80: '#2E3E5E',
    darkNavy60: '#535d7b',
    darkNavy40: '#7D859D',
    darkNavy20: '#B4B7C5',

    colorBlue10: '#E6E6EC',
    colorBlue5: '#F2F1F4',
    colorBlue2: '#F9F6F8',

    porcelainWhite100: '#F9F6F1',
    porcelainWhite80: '#F9F5ED',
    porcelainWhite60: '#FBF8F1',
    porcelainWhite40: '#FDFAF5',
    porcelainWhite20: '#FFFCFA',
    white: '#FFFCFA',

    blazingOrange: '#EA4E38',
    blazingOrange80: '#e5765e',
    blazingOrange60: '#ec9479',
    blazingOrange40: '#f2b69d',
    blazingOrange20: '#f9d7c3',

    naplesYellow: '#FDDB65',

    muted: '#8093AB',
    blue: '#1F487E',
    pink: '#E06C9F',
    red: '#DB5461',
    green: '#558B6E',
    purple: '#966B9D',
    turquoise: '#5BC0BE',
  },

  fonts: {
    body: '"EncodeSansExpanded", sans-serif',
    heading: '"Kanit", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [14, 16, 18, 20, 26, 36, 48, 64],
  fontWeights: {
    body: 400,
    regular: 400,
    bold: 700,
    semibold: 600,
    heading: 500,
    medium: 500,
    display: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.4,
    display: 1.25,
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 128, 256, 512],
  sizes: {
    avatar: 72,
    teamMember: 150,
    author: 128,
    container: 1160,
  },
  radii: {
    default: 3,
    large: 6,
    circle: 99999,
  },
  shadows: {
    sm: `
      0 6px 12px 0 rgba(147, 147, 147, 0.1),
      0 3px 6px 0 rgba(147, 147, 147, 0.05)`,
    md: `
      0 9px 18px 0 rgba(147, 147, 147, 0.2),
      0 3px 6px 0 rgba(147, 147, 147, 0.05)`,
  },
  borders: {
    test: '2px solid red',
  },

  // rebass variants
  text: {
    default: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    heading: {
      color: 'blue',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      wordBreak: 'break-word',

      a: {
        color: 'currentColor',
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
    display: {
      color: 'blue',
      fontFamily: 'heading',
      lineHeight: 'display',
      fontWeight: 'display',
      wordBreak: 'break-word',

      a: {
        color: 'currentColor',
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline',
        },
      },
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    muted: {
      color: 'darkNavy40',
      fontSize: 0,
    },
  },
  forms: {
    blank: {
      p: 2,
      appearance: 'none',
      border: 'none',
      borderRadius: 'default',

      '&:focus': {
        outline: 'none',
      },

      '&::placeholder': {
        color: 'darkNavy40',
      },

      '&[type=number]': {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          m: 0,
        },
      },
    },
    label: {
      fontSize: 1,
    },
    field: {
      p: 2,
      display: 'block',
      width: '100%',
      appearance: 'none',
      fontSize: 'inherit',
      lineHeight: 'inherit',

      fontFamily: 'inherit',
      bg: 'white',
      color: 'darkNavy100',
      border: '1px solid',
      borderColor: 'darkNavy20',
      borderRadius: 'default',
      transition: 'all 100ms ease',

      '&:hover': {
        borderColor: 'darkNavy40',
      },

      '&:focus': {
        outline: 'none',
        borderColor: 'darkNavy40',
        boxShadow: (t) => `0 0 0 4px ${t.colors.colorBlue5}`,
      },
    },
    input: {
      variant: 'forms.field',

      '&::placeholder': {
        color: 'darkNavy40',
      },

      '&[type=number]': {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          m: 0,
        },
      },
    },
    select: {
      variant: 'forms.field',
    },
    textarea: {
      variant: 'forms.field',

      '&::placeholder': {
        color: 'darkNavy40',
      },
    },
    radio: {
      color: 'white',
    },
    checkbox: {
      color: 'darkNavy40',
      '&:checked': {
        color: 'darkNavy100',
      },
    },
  },
  variants: {
    avatar: {
      testimonial: {
        width: 'avatar',
        height: 'avatar',
        borderRadius: 'circle',
        border: '1px solid',
        borderColor: 'darkNavy20',
        bg: 'colorBlue10',
        overflow: 'hidden',
      },
      teamMember: {
        width: 'teamMember',
        height: 'teamMember',
        borderRadius: 'circle',
        border: '1px solid',
        borderColor: 'darkNavy20',
        bg: 'colorBlue10',
        overflow: 'hidden',
        transition: 'transform 100ms ease-out',

        '&:hover': {
          transform: 'translate(-50%, -50%) scale(0.9)',
        },
      },
      author: {
        width: 'author',
        height: 'author',
        borderRadius: 'circle',
        bg: 'colorBlue10',
        overflow: 'hidden',
      },
    },
    badges: {
      default: {
        display: 'inline-block',
        fontSize: 0,
        px: 2,
        py: 1,
        lineHeight: 1,
        borderRadius: 'default',
      },
      fill: {
        variant: 'variants.badges.default',
        bg: 'colorBlue5',
        color: 'darkNavy100',
      },
      outline: {
        variant: 'variants.badges.default',
        border: '1px solid',
        borderColor: 'darkNavy40',
        color: 'darkNavy40',
      },
    },
    hr: {
      bg: 'colorBlue10',
      height: 1,
      width: '100%',
    },
    icon: {
      circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        borderColor: 'darkNavy100',
        borderRadius: 'circle',
        width: [32, 46],
        height: [32, 46],
        flexShrink: 0,
      },
    },
    card: {
      primary: {
        // px: [4, 6],
        // py: [3, 5],
        bg: 'darkNavy100',
        borderRadius: 'large',
        boxShadow: 'md',
      },
      secondary: {
        // px: [4, 6],
        // py: [3, 4],
        bg: 'colorBlue2',
        borderRadius: 'large',
        boxShadow: 'sm',
      },
      outline: {
        // px: [4, 6],
        // py: [3, 4],
        borderRadius: 'large',
        border: '1px solid',
        borderColor: 'colorBlue10',
      },
    },
    link: {
      color: 'currentColor',
      wordBreak: 'break-word',
    },
    nav: {
      header: {
        fontSize: 2,
        fontFamily: 'heading',
        fontWeight: 'medium',
        display: ['block', 'flex'],
        alignItems: 'center',
        justifyContent: 'center',
        px: [4, null, null, 2],
        py: [3, null, null, 2],
        color: 'darkNavy100',
        textDecoration: 'none',
        transition: 'all 100ms ease',

        ':hover,:focus': {
          color: 'blue',
          bg: ['colorBlue5', null, null, 'transparent'],
          borderRadius: 'large',
        },

        '&:not(a)': {
          textDecoration: 'none !important',
          cursor: 'pointer',
        },
      },
      headerMobile: {
        fontSize: 2,
        fontWeight: 'bold',
        display: 'block',
        py: 3,
        color: 'white',
        textDecoration: 'none',
        transition: 'all 100ms ease',

        '&:not(a)': {
          textDecoration: 'none !important',
          cursor: 'pointer',
        },
      },
      headerInner: {
        fontSize: 1,
        display: 'block',
        padding: [3, null, null, 2],
        color: 'white',
        textDecoration: 'none',
        transition: 'all 100ms ease',
        borderRadius: ['default', null, null, 'unset'],

        ':hover,:focus': {
          color: ['white', null, null, 'darkNavy20'],
          backgroundColor: ['darkNavy80', null, null, 'unset'],
        },

        '&:not(a)': {
          textDecoration: 'none !important',
          cursor: 'pointer',
        },
      },
      dropdown: {
        variant: 'variants.nav.header',
        justifyContent: 'flex-start',
        color: 'darkNavy20',
        fontSize: 1,
        ':hover,:focus,.active': {
          color: 'white',
        },
        '& + &': {
          borderTop: '1px solid',
          borderColor: 'darkNavy40',
        },
      },
      menu: {
        fontSize: 1,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: ['auto', null, null, null],
        py: [2, null, null, 2],
        color: 'inherit',
        textDecoration: 'none',

        ':hover,:focus,.active': {
          opacity: 1,
        },
      },
      footer: {
        fontSize: [2, 0],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        flexBasis: ['50%', 'auto'],
        px: [2, 3, 4],
        py: [2, 1],
        color: 'white',
        textDecoration: 'none',
        transition: 'color 100ms ease',

        ':hover,:focus': {
          textDecoration: 'underline',
        },
      },
    },
    modal: {
      container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2147483003,
        overflowX: 'hidden',
        overflowY: 'auto',
      },
      dialog: {
        position: 'relative',
        px: [5],
        bg: 'white',
        boxShadow: 'md',
        borderTop: '3px solid',
        borderColor: 'darkNavy100',
        borderRadius: 'default',
        maxWidth: 800,
        mx: [2, 4, 'auto'],
        my: [2, 12, null, null],
        zIndex: 1,
      },
      backdrop: {
        bg: 'colorBlue2',
        opacity: 0.8,
        position: 'fixed',
        backdropFilter: 'saturate(180%) blur(20px)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
      },
    },
  },
  buttons: {
    default: {
      fontFamily: 'body',
      display: ['flex', 'inline-flex'],
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 1,
      color: 'white',
      bg: 'darkNavy100',
      borderRadius: 'default',
      transition: 'all 100ms ease-out',
      cursor: 'pointer',
      outline: 'none',

      '&[disabled]': {
        bg: 'muted',
        boxShadow: 'none !important',
        transform: 'none !important',
        cursor: 'not-allowed',
        color: 'darkNavy20',
      },
    },
    primary: {
      variant: 'buttons.default',

      '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: ({ colors }) => `0 3px 0 0 ${colors.pink}`,
      },

      '&[data-loading]': {
        color: 'transparent',
      },
    },
    secondary: {
      variant: 'buttons.default',
      color: 'white',
      bg: 'pink',

      '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: ({ colors }) => `inset 0 -3px 0 0 ${colors.darkNavy100}`,
      },

      '&[data-loading]': {
        color: 'transparent',
      },
    },
    outline: {
      variant: 'buttons.default',
      color: 'text',
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'text',
    },
    outlineMuted: {
      variant: 'buttons.default',
      color: 'darkNavy80',
      bg: 'white',
      border: '1px solid',
      borderColor: 'darkNavy20',
    },
    outlineWhiteFlex: {
      fontFamily: 'body',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 1,
      color: 'white',
      borderRadius: 'default',
      transition: 'all 100ms ease-out',
      cursor: 'pointer',
      outline: 'none',
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'white',
    },
    muted: {
      variant: 'buttons.default',
      color: 'white',
      bg: 'blue',

      '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: ({ colors }) => `0 3px 0 0 ${colors.pink}`,
      },
    },
    main: {
      variant: 'buttons.default',
      color: 'white',
      fontSize: 2,
      fontWeight: 'bold',
      bg: 'pink',
      pl: 4,
      textAlign: 'left',

      '&[data-loading]': {
        color: 'transparent',
      },

      '&:before, &:after': {
        position: 'absolute',
        content: '""',
        width: 3,
        left: '10px',
        background: 'white',
        borderRadius: 3,
        transition: 'height 100ms ease-out',
      },

      '&:before': {
        height: '39%',
        top: 0,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
      },

      '&:after': {
        height: '39%',
        bottom: 0,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      },

      '&:hover': {
        '&:before': {
          height: '15%',
        },
        '&:after': {
          height: '63%',
        },
      },
    },
    blank: {
      border: 'none',
      margin: 0,
      padding: 0,
      width: 'auto',
      overflow: 'visible',
      background: 'transparent',
      color: 'inherit',
      font: 'inherit',
      lineHeight: 'normal',
      appearance: 'none',
      cursor: 'pointer',
    },
    v2Main1: {
      fontFamily: 'Kanit',
      fontWeight: '500',
      display: ['flex', 'inline-flex'],
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 1,
      color: 'darkNavy100',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'blazingOrange',
      borderRadius: '32px',
      transition: 'all 100ms ease-out',
      cursor: 'pointer',
      outline: 'none',
      textTransform: 'uppercase',
      padding: '8px 18px',
      textWrap: 'nowrap',

      '&:active, &:hover, &:focus': {
        color: 'porcelainWhite40',
        bg: 'blazingOrange',
      },

      '&[disabled]': {
        color: 'darkNavy40',
        borderColor: 'darkNavy40',
        bg: 'porcelainWhite40',
        cursor: 'not-allowed',
      },
    },
    v2Main2: {
      fontFamily: 'Kanit',
      fontWeight: '500',
      display: ['flex', 'inline-flex'],
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 1,
      color: 'darkNavy100',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'darkNavy100',
      borderRadius: '32px',
      transition: 'all 100ms ease-out',
      cursor: 'pointer',
      outline: 'none',
      textTransform: 'uppercase',
      padding: '8px 18px',
      textWrap: 'nowrap',

      '&:active, &:hover, &:focus': {
        color: 'porcelainWhite100',
        bg: 'darkNavy100',
      },

      '&[disabled]': {
        color: 'darkNavy40',
        borderColor: 'darkNavy40',
        bg: 'porcelainWhite40',
        cursor: 'not-allowed',
      },
    },
    v2Main3: {
      fontFamily: 'Kanit',
      fontWeight: '500',
      display: ['flex', 'inline-flex'],
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 1,
      color: 'porcelainWhite40',
      bg: 'blazingOrange',
      border: '2px solid',
      borderColor: 'blazingOrange',
      borderRadius: '32px',
      transition: 'all 100ms ease-out',
      cursor: 'pointer',
      outline: 'none',
      textTransform: 'uppercase',
      padding: '8px 18px',
      textWrap: 'nowrap',

      '&:active, &:hover, &:focus': {
        color: 'porcelainWhite40',
        bg: 'blazingOrange80',
        borderColor: 'blazingOrange80',
      },

      '&[disabled]': {
        color: 'darkNavy40',
        borderColor: 'darkNavy40',
        bg: 'porcelainWhite40',
        cursor: 'not-allowed',
      },
    },
  },
  styles: {
    root: {
      variant: 'text.default',
      fontSize: [1, 2],
    },
    h1: {
      variant: 'text.display',
      fontSize: [5, 6],
    },
    h2: {
      variant: 'text.display',
      fontSize: [5, 5],
    },
    h3: {
      variant: 'text.heading',
      fontSize: [4, 4],
    },
    h4: {
      variant: 'text.heading',
      fontSize: [2, 3],
    },
    h5: {
      variant: 'text.heading',
      fontSize: [1, 2],
    },
    h6: {
      variant: 'text.heading',
      fontSize: [1, 2],
    },
  },
}

export default theme
